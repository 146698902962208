<template>
	<div id="menu-lateral">
		<div class="row">
			<div class="topo">
				<div class="btn-voltar" @click="estadoMenu = false">
					<v-icon color="#FE8204">mdi-chevron-left</v-icon>
				</div>
				<div class="perfil" v-if="$store.getters.isLoggedIn">
					<div class="infos">
						<span class="nome">
                            {{dados_usuario.nome}}
                        </span>
						<span v-if="dados_usuario.enderecos == ''" class="endereco" @click="modal_endereco_entrega = true">
                            Nenhum endereço cadastrado.
                        </span>
                        <span v-else-if="endereco_selecionado >= 0" class="endereco" @click="modal_endereco_entrega = true">
                            {{$store.getters.clientes.usuario.enderecos[endereco_selecionado].logradouro}},
                            {{$store.getters.clientes.usuario.enderecos[endereco_selecionado].numero}}
                        </span>
                        <span v-else class="endereco" @click="modal_endereco_entrega = true">
                            {{$store.getters.clientes.usuario.enderecos[0].logradouro}},
                            {{$store.getters.clientes.usuario.enderecos[0].numero}}
                        </span>
					</div>
					<div class="icone">
						<img src="@/assets/images/REDIRECTUSUARIO.png" alt="">
					</div>
				</div>
			</div>
			<ul>
				<li>
					<router-link :to="'/' + this.$route.params.url_amigavel + '/perfil'" v-if="$store.getters.isLoggedIn">
						<span class="texto">
							<p>Meu perfil</p>
						</span>
					</router-link>
				</li>
				<li>
					<router-link :to="'/' + this.$route.params.url_amigavel + '/historico-de-pedidos'" v-if="$store.getters.isLoggedIn">
						<span class="texto">
							<p>Meus pedidos</p>
						</span>
					</router-link>
				</li>
				<li>
					<router-link :to="'/' + this.$route.params.url_amigavel + '/alterar-email'" v-if="$store.getters.isLoggedIn">
						<span class="texto">
							<p>Alterar e-mail</p>
						</span>
					</router-link>
				</li>
				<li>
					<router-link :to="'/' + this.$route.params.url_amigavel + '/alterar-senha'" v-if="$store.getters.isLoggedIn">
						<span class="texto">
							<p>Alterar senha</p>
						</span>
					</router-link>
				</li>
				<li>
					<router-link :to="'/' + this.$route.params.url_amigavel + '/privacidade'" v-if="$store.getters.isLoggedIn">
						<span class="texto">
							<p>Termos de Uso e Política de Privacidade</p>
						</span>
					</router-link>
				</li>
				<!-- <li>
					<router-link @click.native="closeMenu" :to="'/'">
						<span class="texto">
							<p>Sobre a empresa</p>
						</span>
					</router-link>
				</li> -->
				<li v-if="$store.getters.screen_size.width <= 768">
					<router-link @click.native="closeMenu" :to="'/' + this.$route.params.url_amigavel + '/logout'">
						<span class="icone">
							<i class="fas fa-sign-out-alt"></i>
						</span>
						<span class="texto">
							<p>
								Sair
							</p>
						</span>
					</router-link>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	import store from '@/store'
	
	export default {
		name: 'MenuLateral',
		components: {
		},
		data: () => ({
			dados_usuario: {},
			estadoMenu: '',
			index_endereco: null,
		}),
		props:{
			estadoMenuLateral:[Function]
		},
		methods: {
			closeMenu(){
				if (store.getters.screen_size.width < 768) {
					store.dispatch('toggleMenu')
				}
			},
			init(){
				}
		},
		mounted() {
			this.init()
			if (store.getters.isLoggedIn) {
				this.dados_usuario = store.getters.clientes.usuario
				let index_array_endereco = JSON.stringify(sessionStorage.getItem('Index array'))
				index_array_endereco = index_array_endereco.replace('Index array', '')
				index_array_endereco = index_array_endereco.replace(/[^a-zA-Z0-9]/g, "")
				this.index_endereco = index_array_endereco
            }
		},
		watch:{
			estadoMenu:function(estadoMenu){
                this.$emit('estadoMenuLateral',estadoMenu)
				this.estadoMenu = ''
            }
        },
		computed:{
            endereco_selecionado: function (){
                return this.index_endereco
            }
        }
	}
</script>

<style lang="scss">
	#menu-lateral{
		text-align: center;
		padding: 0 12px;
		height: 100vh;
		position: relative;
		z-index: 1;
		background-color: #242424;
		font-family: 'Poppins';
		font-weight: 400;
		overflow-x: hidden;
		overflow-y: auto;
		@media (max-width: 767px){
			background-color: #fff;
		}
		.topo{
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex: 1;
			padding: 30px 15px;
			.btn-voltar{
				display: flex;
				flex-wrap: wrap;
				justify-content: flex-start;
				width: max-content;
				padding: 10px 15px;
				@media (max-width: 767px){
					justify-content: center;
					align-items: center;
					width: 30px;
					height: 30px;
					padding: 0;
					border: 0.1px solid #FE8204;
				}
				svg{
					path{
						fill: #FE8204;
					}
				}
			}
			.perfil{
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				.nome{
					margin-right: 10px;
					color: #fff;
				}
			}
			.perfil{
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				.infos{
					.nome{
						margin-right: 10px;
						color: #fff;
					}
					.endereco{
						display: none;
					}
					@media (max-width: 767px){
						display: flex;
						flex-wrap: wrap;
						flex-direction: column;
						justify-content: flex-end;
						align-items: flex-end;
						.nome{
							margin-right: 10px;
							font-family: 'Poppins';
							font-style: normal;
							font-weight: normal;
							font-size: 16px;
							line-height: 24px;
							text-align: center;
							color: #747474;
						}
						.endereco{
							display: block;
							font-family: 'Josefin Sans';
							font-style: normal;
							font-weight: normal;
							font-size: 12px;
							line-height: 12px;
							text-align: center;
							color: #FE8204;
						}
					}
				}
			}
		}
		ul{
			padding: 0;
			list-style: none;
			margin: 0;
			width: 100%;
			border-top: 1px solid #747474;
			li{
				border-bottom: 1px solid #747474;
				a{
					padding: 15px 30px;
					display: flex;
					transition: all cubic-bezier(0, 1.03, 0.57, 0.92) .3s;
					align-items: center;
					font-family: 'Poppins';
					font-style: normal;
					font-weight: normal;
					font-size: 16px;
					line-height: 24px;
					color: #404040;
					.icone{
						margin-right: 10px;
						width: 20px;
						display: flex;
						align-items: center;
						justify-content: center;
						transition: all cubic-bezier(0, 1.03, 0.57, 0.92) .3s;
					}
					p{
						text-align: left;
						margin-bottom: 0;
						position: relative;
						top: 1px;
						@media (max-width: 430px){
							font-size: 15px;
						}
					}
					&.active,
					&:hover{
						background-color: $primarycolor;
					}
				}
			}
		}
	}
</style>