<template>
    <figure id="icone-localizacao">
        <img class="img-responsive" alt="IconeLocalizacao" src="@/assets/images/place.png">
    </figure>
</template>

<script>
	export default {
		name: 'IconeLocalizacao',
		data: () => ({
        }),
        props: {
            href:[String]
        },
	}
</script>

<style lang="scss">
	#icone-localizacao{
		justify-content: center;
		align-items: center;
		display: flex;
		flex-wrap: wrap;
		margin: auto;
		max-width: 250px;
		margin-bottom: 10px;
		margin-top: 10px;
		img{
			width: 100%;
		}
	}
</style>