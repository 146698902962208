<template>
    <div id="loader">
        <div class="bg">
        </div>
        <div class="logo">
            <LogoFinerFoodsBranca/>
        </div>
    </div>
</template>

<script>
    import LogoFinerFoodsBranca from '@/components/LogoFinerFoodsBranca.vue'

	export default {
		name: 'Loader',
        components: {
            LogoFinerFoodsBranca
        },
		data: () => ({
			dialog_loading: true
		})
	}
</script>

<style lang="scss">
    #loader{
        position: absolute;
        background-color: #40404033;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 2;
        overflow: hidden;
        .bg{
            position: absolute;
            backdrop-filter: blur(4px);
            z-index: 100;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
        }
        .logo{
            position: absolute;
            top: 50%;
            left: 50%;
            max-width: 100%;
            max-height: 100%;
            transform: translate(-50%, -50%);
            z-index: 101;
            img{
                animation: fadein 2s infinite alternate;
            }
        }
        @keyframes fadein {
            from{ 
                opacity: 0; 
                transform: scale(1.1);
            }
            to{
                opacity: 1;
                transform: scale(1.4);
            }
        }
    }
</style>