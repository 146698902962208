<template>
    <div id="navBarCardapio">
        <div class="container container-cardapio">
            <div class="row row-cardapio-mobile" v-if="$store.getters.screen_size.width <= 767">
                <div class="col-2 wrapper-btns" v-if="$store.getters.isLoggedIn">
                    <button class="voltar-cardapio" @click="voltarAoCardapio()" v-if="$router.history.current.name == 'FinalizarCompra'">
                        <v-icon  color="#FE8204">mdi-arrow-left</v-icon>
                    </button>
                    <div v-else>
                        <div class="div-menu-lateral" v-bind:class="{ open: abreMenu == true }">
                            <MenuLateral @estadoMenuLateral="estadoMenuLateral"/>
                        </div>
                        <button class="btn-menu" @click="estadoMenuLateral(true)">
                            <v-icon color="#FE8204">mdi-menu</v-icon>
                        </button>
                    </div>
                </div>
                <div class="col-8 div-usuario" v-if="$store.getters.isLoggedIn">
                    <div class="endereco-entrega">
                        <span v-if="dados_usuario.enderecos == ''" class="endereco" @click="modal_endereco_entrega = true">
                            Nenhum endereço cadastrado.
                            <v-icon>far fa-plus-square</v-icon>
                        </span>
                        <span v-else-if="endereco_selecionado >= 0" class="endereco" @click="modal_endereco_entrega = true">
							<template>
								{{dados_usuario.enderecos[endereco_selecionado].logradouro}},
								{{dados_usuario.enderecos[endereco_selecionado].numero}}
							</template>
                            <v-icon>far fa-edit</v-icon>
                        </span>
                        <span v-else class="endereco" @click="modal_endereco_entrega = true">
							<template>
								{{dados_usuario.enderecos[0].logradouro}},
								{{dados_usuario.enderecos[0].numero}}
							</template>
                            <v-icon>far fa-edit</v-icon>
                        </span>
                    </div>
                </div>
                <div :class="!$store.getters.isLoggedIn ? 'col-2 div-estabelecimento mx-auto' : 'col-2 div-estabelecimento'">
                    <div class="logo py-1">
                        <figure>
                            <img :src="logo_estabelecimento" alt="">
                        </figure>
                    </div>
                </div>
            </div>
            <div class="row" v-else>
                <div class="col-md-3 col-sm-2 col-12 div-estabelecimento">
                    <div class="logo">
                        <figure>
                            <img :src="logo_estabelecimento" alt="">
                        </figure>
                    </div>
                    <div class="nome-estabelecimento">
                        {{nome_estabelecimento}}
                    </div>
                </div>
                <div class="col-md-9 col-sm-10 col-12 div-usuario">
                    <div class="endereco-entrega" v-if="$store.getters.isLoggedIn">
                        <span class="entregar-em">
                            ENDEREÇO DE ENTREGA:
                        </span>
                        <span v-if="dados_usuario.enderecos == ''" class="endereco" @click="modal_endereco_entrega = true">
                            Nenhum endereço cadastrado.
                            <v-icon>far fa-plus-square</v-icon>
                        </span>
                        <span v-else-if="endereco_selecionado >= 0" class="endereco" @click="modal_endereco_entrega = true">
                            {{$store.getters.clientes.usuario.enderecos[endereco_selecionado].logradouro}},
                            {{$store.getters.clientes.usuario.enderecos[endereco_selecionado].numero}}
                            <v-icon>far fa-edit</v-icon>
                        </span>
                        <span v-else class="endereco" @click="modal_endereco_entrega = true">
                            {{$store.getters.clientes.usuario.enderecos[0].logradouro}},
                            {{$store.getters.clientes.usuario.enderecos[0].numero}}
                            <v-icon>far fa-edit</v-icon>
                        </span>
                    </div>
                    <div class="infos" v-if="$store.getters.isLoggedIn">
                        <span class="span_bem_vindo">
                            Bem vindo (a) 
                        </span>
                        <span class="nome">
                            {{dados_usuario.nome}}
                        </span>
                    </div>
                    <div class="icones">
                        <router-link :to="'/' + this.$route.params.url_amigavel + '/perfil'" v-if="$store.getters.isLoggedIn">
                            <img :src="icone_Usuario" alt="">
                        </router-link>
                        <router-link :to="'/' + this.$route.params.url_amigavel + '/login'" v-if="$store.getters.isLoggedIn == false">
                            <img :src="icone_Usuario" alt="">
                        </router-link>
                        <button @click="openModalBusca">
                            <v-icon color="#FE8204">mdi-magnify</v-icon>
                        </button>
                        <router-link :to="'/' + this.$route.params.url_amigavel + '/historico-de-pedidos'" v-if="$store.getters.isLoggedIn">
                            <figure id="logo-historico">
                                <img class="img-responsive" alt="Logo" src="@/assets/images/historico.png">
                            </figure>
                        </router-link>
                        <router-link :to="'/' + this.$route.params.url_amigavel + '/logout'" v-if="$store.getters.isLoggedIn">
                            <img :src="icone_Sair" alt="">
                        </router-link>
                    </div>
                </div>
            </div>
            <v-dialog v-model="modal_endereco_entrega" max-width="600px">
                <v-card id="modal-endereco">
                    <IconeLocalizacao/>
                    <div class="titulo-modal">
                        Onde deseja receber seu pedido?
                    </div>
                    <v-card>
                        <v-list>
                            <v-list-item
                                v-for="(endereco, index) in dados_usuario.enderecos"
                                :key="endereco.codigo_endereco"
                            >
                                <v-list-item-content @click="trocaEndereco(index)">
                                    <v-list-item-title v-if="endereco.complemento == ''" v-text="endereco.logradouro + ', ' +endereco.numero">
                                    </v-list-item-title>
                                    <v-list-item-title v-else v-text="endereco.logradouro + ', ' +endereco.numero + ', ' + endereco.complemento">
                                    </v-list-item-title>
                                    <v-list-item-subtitle v-if="endereco.titulo_endereco != ''">
                                        {{endereco.titulo_endereco}}
                                    </v-list-item-subtitle>
                                </v-list-item-content>

                                <v-list-item-action>
                                    <v-btn icon @click="editarEndereco(endereco.codigo_endereco)">
                                        <v-icon color="grey lighten-1">fas fa-edit</v-icon>
                                    </v-btn>
                                    <v-btn v-if="index>0" icon @click="removerEndereco(endereco.codigo_endereco)">
                                        <v-icon style="width: 16px" color="red">fas fa-trash-alt</v-icon>
                                    </v-btn>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>
                    </v-card>
                    <v-card-actions>
                        <button class="secondary-button" @click="modal_endereco_entrega = !modal_endereco_entrega">
                            Cancelar
                        </button>
                        <button class="primary-button" @click="modal_adicionar_endereco = true; limparCampos()">
                            Adicionar
                        </button>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="modal_adicionar_endereco" max-width="600px">
                <v-card id="modal-adicionar-endereco">
                    <v-card-title>
                        Adicionar novo endereço
                    </v-card-title>
                    <v-card-text>
                        <v-form ref="form_novo_endereco" class="row">
                            <div class="col-12">
                                <label class="primary-text-field-label">Nomear endereço</label>
                                <v-text-field
                                    class="primary-text-field"
                                    v-model="novoEndereco.titulo_endereco"
                                    placeholder="Nomear endereço"
                                    solo
                                    hide-details
                                />
                            </div>
                            <div class="col-12">
                                <label class="primary-text-field-label">CEP*</label>
                                <v-text-field
                                    class="primary-text-field"
                                    :rules="[v => !!v || 'Campo obrigatório']" 
                                    v-model="novoEndereco.cep"
                                    placeholder="CEP"
                                    solo
                                    hide-details
                                    v-mask="'#####-###'"
								    @change="buscaCep()"
                                />
                            </div>
                            <div class="col-12">
                                <label class="primary-text-field-label">Endereço*</label>
                                <v-text-field
                                    class="primary-text-field"
                                    :rules="[v => !!v || 'Campo obrigatório']" 
                                    v-model="novoEndereco.logradouro"
                                    placeholder="Endereço"
                                    solo
                                    hide-details
                                />
                            </div>
                            <div class="col-12">
                                <label class="primary-text-field-label">Número*</label>
                                <v-text-field
                                    class="primary-text-field"
                                    :rules="[v => !!v || 'Campo obrigatório']" 
                                    v-model="novoEndereco.numero"
                                    placeholder="Número"
                                    type="number"
                                    solo
                                    hide-details
                                />
                            </div>
                            <div class="col-12">
                                <label class="primary-text-field-label">Estado*</label>
                                <v-select
                                    class="primary-select"
                                    :rules="[v => !!v || 'Campo obrigatório']" 
                                    :items="estados"
                                    item-text="nome" 
                                    item-value="codigo"
                                    v-model="novoEndereco.uf_id" 
                                    placeholder="Estado"
                                    solo
                                    hide-details
                                    @change="buscaCidades()"
                                />
                            </div>
                            <div class="col-12">
                                <label class="primary-text-field-label">Cidade*</label>
                                <v-select
                                    class="primary-select"
                                    :rules="[v => !!v || 'Campo obrigatório']" 
                                    :items="cidades"
                                    item-text="nome" 
                                    item-value="codigo"
                                    v-model="novoEndereco.cidade_id" 
                                    placeholder="Cidade"
                                    solo
                                    hide-details
                                />
                            </div>
                            <div class="col-12">
                                <label class="primary-text-field-label">Bairro*</label>
                                <v-text-field
                                    class="primary-text-field"
                                    :rules="[v => !!v || 'Campo obrigatório']" 
                                    v-model="novoEndereco.bairro"
                                    placeholder="Bairro"
                                    solo
                                    hide-details
                                />
                            </div>
                            <div class="col-12">
                                <label class="primary-text-field-label">Complemento</label>
                                <v-text-field
                                    class="primary-text-field"
                                    v-model="novoEndereco.complemento"
                                    placeholder="Complemento"
                                    solo
                                    hide-details
                                />
                            </div>
                            <div class="col-12">
                                <label class="primary-text-field-label">Ponto de Referência</label>
                                <v-text-field
                                    class="primary-text-field"
                                    v-model="novoEndereco.ponto_referencia"
                                    placeholder="Ponto de Referência"
                                    solo
                                    hide-details
                                />
                            </div>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <div class="wrapper-btns">
                            <button class="secondary-button" @click="modal_adicionar_endereco = !modal_adicionar_endereco">
                                Cancelar
                            </button>
                            <button class="primary-button" @click="adicionarEndereco()">
                                Salvar
                            </button>
                        </div>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="modal_editar_endereco" max-width="600px">
                <v-card id="modal-adicionar-endereco">
                    <v-card-title>
                        Editar endereço
                    </v-card-title>
                    <v-card-text>
                        <v-form ref="form_novo_endereco" class="row">
                            <div class="col-12">
                                <label class="primary-text-field-label">Nomear endereço</label>
                                <v-text-field
                                    class="primary-text-field"
                                    v-model="novoEndereco.titulo_endereco"
                                    placeholder="Nomear endereço"
                                    solo
                                    hide-details
                                />
                            </div>
                            <div class="col-12">
                                <label class="primary-text-field-label">CEP*</label>
                                <v-text-field
                                    class="primary-text-field"
                                    :rules="[v => !!v || 'Campo obrigatório']" 
                                    v-model="novoEndereco.cep"
                                    placeholder="CEP*"
                                    solo
                                    hide-details
                                    v-mask="'#####-###'"
								    @change="buscaCep()"
                                />
                            </div>
                            <div class="col-12">
                                <label class="primary-text-field-label">Endereço*</label>
                                <v-text-field
                                    class="primary-text-field"
                                    :rules="[v => !!v || 'Campo obrigatório']" 
                                    v-model="novoEndereco.logradouro"
                                    placeholder="Endereço*"
                                    solo
                                    hide-details
                                />
                            </div>
                            <div class="col-12">
                                <label class="primary-text-field-label">Número*</label>
                                <v-text-field
                                    class="primary-text-field"
                                    :rules="[v => !!v || 'Campo obrigatório']" 
                                    v-model="novoEndereco.numero"
                                    placeholder="Número*"
                                    type="number"
                                    solo
                                    hide-details
                                />
                            </div>
                            <div class="col-12">
                                <label class="primary-text-field-label">UF*</label>
                                <v-select
                                    class="primary-select"
                                    :rules="[v => !!v || 'Campo obrigatório']" 
                                    :items="estados"
                                    item-text="nome" 
                                    item-value="codigo"
                                    v-model="novoEndereco.uf_id" 
                                    placeholder="UF*"
                                    solo
                                    hide-details
                                    @change="buscaCidades()"
                                />
                            </div>
                            <div class="col-12">
                                <label class="primary-text-field-label">Cidade*</label>
                                <v-select
                                    class="primary-select"
                                    :rules="[v => !!v || 'Campo obrigatório']" 
                                    :items="cidades"
                                    item-text="nome" 
                                    item-value="codigo"
                                    v-model="novoEndereco.cidade_id" 
                                    placeholder="Cidade*"
                                    solo
                                    hide-details
                                />
                            </div>
                            <div class="col-12">
                                <label class="primary-text-field-label">Bairro*</label>
                                <v-text-field
                                    class="primary-text-field"
                                    :rules="[v => !!v || 'Campo obrigatório']" 
                                    v-model="novoEndereco.bairro"
                                    placeholder="Bairro*"
                                    solo
                                    hide-details
                                />
                            </div>
                            <div class="col-12">
                                <label class="primary-text-field-label">Complemento</label>
                                <v-text-field
                                    class="primary-text-field"
                                    v-model="novoEndereco.complemento"
                                    placeholder="Complemento"
                                    solo
                                    hide-details
                                />
                            </div>
                            <div class="col-12">
                                <label class="primary-text-field-label">Ponto de Referência</label>
                                <v-text-field
                                    class="primary-text-field"
                                    v-model="novoEndereco.ponto_referencia"
                                    placeholder="Ponto de Referência"
                                    solo
                                    hide-details
                                />
                            </div>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <div class="wrapper-btns">
                            <button class="secondary-button" @click="modal_editar_endereco = !modal_editar_endereco">
                                Cancelar
                            </button>
                            <button class="primary-button" @click="salvarEnderecoEditado()">
                                Salvar
                            </button>
                        </div>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <ModalBusca 
                v-if="showModalBusca"
                :show="showModalBusca"
                @handleClose="closeModalBusca"
            />
        </div>
    </div>
</template>

<script>
	// importa o store
	import store from '@/store'
    import IconeUsuario from '@/assets/images/REDIRECTUSUARIO.png'
    import IconeHome from '@/assets/images/HOMEICON.png'
    import IconeHomeBranca from '@/assets/images/HOMEICON-branca.png'
    import IconeSair from '@/assets/images/SAIR.png'
    import IconeLocalizacao from '@/components/IconeLocalizacao.vue'
    import MenuLateral from '@/components/MenuLateral.vue'
    import ModalBusca from '@/components/ModalBusca.vue'

    export default {
        props: ["pedidos"],
        name: 'NavBarCardapio',
        components:{
            IconeLocalizacao,
            MenuLateral,
            ModalBusca
        },
        data: () => ({
            logo_estabelecimento: '',
            nome_estabelecimento: '',
            icone_Usuario: IconeUsuario,
            icone_Home: IconeHome,
            icone_Home_Branca: IconeHomeBranca,
            icone_Sair: IconeSair,
            dados_usuario: {},
            showModalBusca: false,
            novoEndereco: {
                titulo_endereco: '',
                cep: '',
                logradouro: '',
                numero: '',
                uf_id: '',
                cidade_id: '',
                bairro: '',
                complemento: '',
                ponto_referencia: '',
            },
            modal_endereco_entrega: false,
            modal_adicionar_endereco: false,            
            modal_editar_endereco: false,            
            estados: [],
            cidades: [],
            index_endereco: -1,
            abreMenu: '',
            cidade_id_cep: ''            
        }),
        methods: {
            openModalBusca(){
				this.showModalBusca = true
			},
			closeModalBusca(){
				this.showModalBusca = false
			},
            async init(){
                if (store.getters.isLoggedIn) {
                    this.dados_usuario = await store.getters.clientes.usuario
                    
                    let index_array_endereco = JSON.stringify(sessionStorage.getItem('Index array'))
                    index_array_endereco = index_array_endereco.replace('Index array', '')
                    index_array_endereco = index_array_endereco.replace(/[^a-zA-Z0-9]/g, "")
                    this.index_endereco = index_array_endereco
                }
                this.logo_estabelecimento = store.getters.empresa_cardapio.imagem_logo
                this.nome_estabelecimento = store.getters.empresa_cardapio.nome
                
                await this.buscaEstados()
                await this.buscaCidades()
            },
            estadoMenuLateral(value){
                this.abreMenu = value
            },
            voltaHomeCardapio(){
                this.$router.push('/' + this.$route.params.url_amigavel + '/home/')
            },
            async voltarAoCardapio(){
                await this.atualizaPedido()
                this.$router.push('/' + this.$route.params.url_amigavel)
            },
            async atualizaPedido(){
                let atualizaPedido = JSON.stringify(this.pedidos)
                await localStorage.setItem("pedidoCarrinho", atualizaPedido)
            },
            async buscaEstados(){
				var resp = await store.dispatch('getEstado');
				this.estados = resp
			},
			async buscaCidades(){
                if(this.novoEndereco.uf_id != null){
                    var resp = await store.dispatch('getCidade', this.novoEndereco.uf_id)
				    this.cidades = resp
					this.novoEndereco.cidade_id = this.cidade_id_cep
                }
			},
			async buscaCep(){
				this.cidade_id_cep = ''

				if(this.novoEndereco.cep != null){
					var resp = await store.dispatch('getCep', this.novoEndereco.cep)

					if(resp.sucesso){
						this.novoEndereco.uf_id = resp.dados.estado
						this.novoEndereco.logradouro = resp.dados.logradouro
						this.novoEndereco.bairro = resp.dados.bairro
						this.novoEndereco.numero = ''
						this.cidade_id_cep = resp.dados.cidade

						this.buscaCidades()
					}else{
						this.novoEndereco.uf_id = ''
						this.novoEndereco.logradouro = ''
						this.novoEndereco.bairro = ''
						this.$swal({
							title: 'Atenção',
							icon:'error',
							text: resp.dados.message,
							showCancelButton: false,
							showCloseButton: false,
							showConfirmButton: true,
							confirmButtonText:'Ok'
						})
					}
				}
			},
            async adicionarEndereco(){
                if(this.novoEndereco.cep == '' || this.novoEndereco.logradouro == '' || this.novoEndereco.numero == '' ||this.novoEndereco.uf_id == '' || this.novoEndereco.cidade_id == '' || this.novoEndereco.bairro == ''){
                    this.$swal({
                        title: 'Atenção',
                        icon:'warning',
                        text: 'Por favor, preencha todos os campos obrigatórios!',
                        showCancelButton: false,
                        showCloseButton: false,
                        showConfirmButton: true,
                        confirmButtonText:'Ok'
                    })
                }
                let temp_endereco = {
                    codigo: this.dados_usuario.id,
                    endereco: [{
                        titulo_endereco: this.novoEndereco.titulo_endereco,
                        cep: this.novoEndereco.cep,
                        logradouro: this.novoEndereco.logradouro,
                        numero: this.novoEndereco.numero,
                        uf_id: this.novoEndereco.uf_id,
                        cidade_id: this.novoEndereco.cidade_id,
                        bairro: this.novoEndereco.bairro,
                        complemento: this.novoEndereco.complemento,
                        ponto_referencia: this.novoEndereco.ponto_referencia,
                    }]
                }
				if (this.$refs.form_novo_endereco.validate()) {
                    this.loading = await true
                    var resp = await store.dispatch('adicionaEndereco', temp_endereco)
                    if (resp.sucesso) {
                        this.$swal({
                            icon:'success',
                            text: 'Endereço cadastrado com sucesso!',
                            showCancelButton: false,
                            showCloseButton: false,
                            showConfirmButton: true,
                            confirmButtonText:'Ok'
                        })
                        this.loading = await false
                        this.modal_adicionar_endereco = false
                        this.modal_endereco_entrega = false
                    }else{
                        this.$swal({
                            title: 'Atenção',
                            icon:'warning',
                            text: 'Não foi possível inserir o novo endereço! Verifique os dados.',
                            showCancelButton: false,
                            showCloseButton: false,
                            showConfirmButton: true,
                            confirmButtonText:'Ok'
                        })
                        this.loading = await false
                    }
				}
            },
			async removerEndereco(codigo_endereco){
                
                await this.trocaEndereco(0)
                let dados = {
                    codigo_endereco: codigo_endereco,
                    usuario_id: this.dados_usuario.id
                }

				this.loading = true

				var resp = await store.dispatch('RemoverEndereco', dados)

				if(resp.status == 200){
                    this.$swal({
                        icon:'success',
					    text: 'Endereço removido com sucesso!',
                        showCancelButton: false,
                        showCloseButton: false,
                        showConfirmButton: true,
                        confirmButtonText:'Ok'
                    })

                    var CodigoEnderecoDeEntrega = {'Código endereço de entrega': this.dados_usuario.enderecos[0].codigo_endereco};
                    CodigoEnderecoDeEntrega = JSON.stringify(CodigoEnderecoDeEntrega);
                    sessionStorage.setItem('Código endereço de entrega', CodigoEnderecoDeEntrega);

                    this.init()
				}else{
                    this.$swal({
                        title: 'Atenção',
                        icon:'warning',
					    text: 'Não foi possível remover o endereço!',
                        showCancelButton: false,
                        showCloseButton: false,
                        showConfirmButton: true,
                        confirmButtonText:'Ok'
                    })
				}
				// retira o loading do componente
				this.loading = false
			},
			async editarEndereco(codigo_endereco){
                
                let dados = {
                    codigo_endereco: codigo_endereco,
                    usuario_id: this.dados_usuario.id
                }

				this.loading = true

				var resp = await store.dispatch('getEndereco', dados)

				if(resp.status == 200){
					this.novoEndereco.codigo_endereco = await resp.data.dados.usuario.enderecos[0].codigo_endereco || ''
					this.novoEndereco.titulo_endereco = await resp.data.dados.usuario.enderecos[0].titulo_endereco || ''
					this.novoEndereco.cep = await resp.data.dados.usuario.enderecos[0].cep || ''
					this.novoEndereco.logradouro = await resp.data.dados.usuario.enderecos[0].logradouro || ''
					this.novoEndereco.numero = await resp.data.dados.usuario.enderecos[0].numero || ''
                    await this.buscaEstados()
					this.novoEndereco.uf_id = await resp.data.dados.usuario.enderecos[0].codigo_estado || ''
                    await this.buscaCidades()
					this.novoEndereco.cidade_id = await resp.data.dados.usuario.enderecos[0].codigo_cidade || ''
					this.novoEndereco.bairro = await resp.data.dados.usuario.enderecos[0].bairro || ''
					this.novoEndereco.complemento = await resp.data.dados.usuario.enderecos[0].complemento || ''
					this.novoEndereco.ponto_referencia = await resp.data.dados.usuario.enderecos[0].ponto_referencia || ''
                    
					this.modal_editar_endereco = true
                    this.init()
				}else{
                    this.$swal({
                        title: 'Atenção',
                        icon:'warning',
					    text: 'Não é possível alterar este endereço!',
                        showCancelButton: false,
                        showCloseButton: false,
                        showConfirmButton: true,
                        confirmButtonText:'Ok'
                    })
				}
				// retira o loading do componente
				this.loading = false
			},
			async salvarEnderecoEditado(){
                
                let dados = {
                    codigo_endereco: this.novoEndereco.codigo_endereco,
                    usuario_id: this.dados_usuario.id,
                    endereco: {
                        titulo_endereco: this.novoEndereco.titulo_endereco,
                        cep: this.novoEndereco.cep,
                        logradouro: this.novoEndereco.logradouro,
                        numero: this.novoEndereco.numero,                        
                        cidade_id: this.novoEndereco.cidade_id == '' ? null : this.novoEndereco.cidade_id,
                        bairro: this.novoEndereco.bairro,
                        complemento: this.novoEndereco.complemento,
                        ponto_referencia: this.novoEndereco.ponto_referencia,
                    }
                }

				this.loading = true

				var resp = await store.dispatch('salvaEnderecoEditado', dados)

				if(resp.status == 200){
                    this.$swal({
                        icon:'success',
					    text: 'Endereço editado com sucesso!',
                        showCancelButton: false,
                        showCloseButton: false,
                        showConfirmButton: true,
                        confirmButtonText:'Ok'
                    })
					this.modal_editar_endereco = false

				}else{
                    this.$swal({
                        title: 'Atenção',
                        icon:'error',
					    text: 'Não é possível alterar este endereço!',
                        showCancelButton: false,
                        showCloseButton: false,
                        showConfirmButton: true,
                        confirmButtonText:'Ok'
                    })
				}
				// retira o loading do componente
				this.loading = false
			},
            async trocaEndereco(index_endereco){
                var CodigoEnderecoDeEntrega = {'Código endereço de entrega': this.dados_usuario.enderecos[index_endereco].codigo_endereco};
                CodigoEnderecoDeEntrega = JSON.stringify(CodigoEnderecoDeEntrega);
                sessionStorage.setItem('Código endereço de entrega', CodigoEnderecoDeEntrega);
                
                var IndexArrayEndereco = {'Index array': index_endereco};
                IndexArrayEndereco = JSON.stringify(IndexArrayEndereco);
                sessionStorage.setItem('Index array', IndexArrayEndereco);

                this.index_endereco = index_endereco
                this.modal_endereco_entrega = false
            },
            async limparCampos(){
                this.novoEndereco.titulo_endereco = ''
                this.novoEndereco.cep = ''
                this.novoEndereco.logradouro = ''
                this.novoEndereco.numero = ''
                this.novoEndereco.uf_id = ''
                this.novoEndereco.cidade_id = ''
                this.novoEndereco.bairro = ''
                this.novoEndereco.complemento = ''
                this.novoEndereco.ponto_referencia = ''
            }
		},
		// funções que rodam quando o componente é montado
		async mounted(){
            this.init()
		},
        computed:{
            endereco_selecionado: function (){
                return this.index_endereco
            }
        }
    }
</script>

<style lang="scss">
    #navBarCardapio{
        display: flex;
        align-items: center;
        height: auto;        
        .row-cardapio-mobile{
            border-bottom: 1px solid #4f4f4f;
        }
        .wrapper-btns{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            .voltar-cardapio{
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 27px;
                color: #fff;
                display: flex;
                align-items: center;
                figure{
                    justify-content: center;
                    align-items: center;
                    display: flex;
                    flex-wrap: wrap;
                    margin: auto;
                    max-width: 75px;
                    max-height: 75px;
                    img{
                        width: 100%;
                    }
                }
            }
            .nome-estabelecimento{
                padding-left: 40px;
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 300;
                font-size: 20px;
                line-height: 30px;
                text-align: center;
                color: #000000;
                padding: 0 12px;
                @media (max-width: 767px){
                    padding-left: 0;
                }
                svg{
                    margin-right: 14px;
                    path{
                        fill: $primarycolor;
                    }
                }
                @media (max-width: 550px){
                    span{
                        display: none;
                    }
                    svg{
                        margin-right: 7px;
                        font-size: 16px;
                    }
                }
            }
            @media (max-width: 375px){
                top: 45%;
                .voltar-cardapio{
                    font-size: 15px;
                    svg{
                        font-size: 15px;
                        margin-right: 7px;
                    }
                }
            }
        } 
        .div-usuario{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-end;
            font-family: 'Poppins';
            padding: 0 12px;
            .endereco-entrega{
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                margin-right: 15px;
                min-width: 250px;
                color: #000;
                font-weight: 400;
                .entregar-em{
                    font-size: 14px;
                    font-weight: 700;
                }
                .endereco{
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    font-size: 16px;
                    cursor: pointer;
                    svg{
                        font-size: 17px;
                        margin-left: 5px;
                        path{
                            fill: $primarycolor;
                        }
                    }
                    @media (max-width: 767px){
                        justify-content: center;
                    }
                    @media (max-width: 550px){
                        font-size: 14px;
                        justify-content: center;
                        svg{
                            font-size: 14px;
                        }
                    }
                    @media (max-width: 350px){
                        font-size: 12px;
                        svg{
                            font-size: 12px;
                        }
                    }
                }
                @media (max-width: 767px){
                    margin-right: 0;
                    min-width: 100%;
                    color: #FFF;
                }
            }
            .infos{
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                align-items: center;
                span{
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    text-align: right;
                    color: #000000;
                    &.span_bem_vindo{
                        font-size: 14px;
                        font-weight: 700;
                        text-transform: uppercase;
                    }
                    &.localizacao{
                        font-size: 18;
                        font-family: 'JosefinSans';
                    }
                }
            }
            .icones{
                display: flex;
                flex-wrap: wrap;
                button{
                    margin-left: 25px;
                    padding: 0;
                    font-size: 30px;
                    height: 32px;
                    width: 32px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    i{
                        font-size: 37px;
                    }
                }
                img{
                    padding-left: 25px;
                    height: 32px;
                }
                svg{
                    font-size: 30px;
                    margin-left: 20px;
                    path{
                        fill: #fe8204;
                    }
                }
            }
            @media (max-width: 600px){
                justify-content: center;
                .endereco{
                    justify-content: center;
                    text-align: center;
                }
            }
        }
        .div-estabelecimento{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            padding: 0 12px;
            .logo{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                figure{
                    justify-content: center;
                    align-items: center;
                    display: flex;
                    flex-wrap: wrap;
                    margin: auto;
                    max-width: 75px;
                    max-height: 75px;
                    margin-bottom: 0px;
                    margin-top: 0px;
                    img{
                        width: 100%;
                    }
                    @media (max-width: 767px) {
                        border-radius: 100%;
                        background: #fff;
                        overflow: hidden;
                    }
                }
            }
            .nome-estabelecimento{
                padding-left: 40px;
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 300;
                font-size: 20px;
                line-height: 30px;
                text-align: center;
                color: #000000;
                @media (max-width: 1000px){
                    display: none;
                }
            }
            @media (max-width: 767px){
                justify-content: flex-end;
            }
            @media (max-width: 600px){
                justify-content: center;
            }
            @media (max-width: 315px){
                visibility: hidden;
            }
        }
        .container-cardapio{
            @media (max-width: 767px){
                padding: 0 12px;
                background-color: #404040;
                color: rgb(255, 255, 255);
            }
        }
        @media (max-width: 767px){
            .div-estabelecimento{
                .nome-estabelecimento{
                    font-size: 18px;
                    line-height: 20px;
                    color: #fff;
                }
            }
        }
        .div-menu-lateral{
            position: fixed;
            top: 0;
            transition: all cubic-bezier(0, 1.03, 0.57, 0.92) .3s;
            width: 60px;
            right: 100%;
            max-width: 100vw;
            z-index: 100;
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
            box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
            max-height: 100vh;
            @media (max-width: 767px){
                box-shadow: none;
                width: 100vh;
            }
            .background{
                display: block;
                background-color: #33333333;
                content: "";
                backdrop-filter: blur(7px);
                position: absolute;
                left: 100%;
                top: 0;
                visibility: hidden;
                opacity: 0;
                width: 100vw;
                height: 100vh;
            }
            .wrapper-btn-minimize{
                display: flex;
                justify-content: flex-end;
                .btn-minimize{
                    border: none;
                    background-color: transparent;
                    align-self: flex-end;
                    transform: rotate(-180deg);
                    transition: all cubic-bezier(0, 1.03, 0.57, 0.92) .3s;
                    height: 52px;
                }
            }
            &.open{
                width: 100vw;
                z-index: 10;
                transform: translateX(100%);
                background-color: #fff;
                .btn-minimize{
                    transform: rotate(0deg);
                }
                .background{
                    visibility: visible;
                    opacity: 1;
                }
            }
        }
    }
    #modal-endereco{
        padding: 20px;
        font-family: 'Poppins';
        .titulo-modal{
            text-align: center;
            font-weight: 600;
            padding-bottom: 15px;
        }
        .v-card{
            box-shadow: none;
        }
        .v-list-item{
            cursor: pointer;            
            box-shadow: 0 3px 1px -2px rgba(0,0,0,0.2), 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12);
            margin-bottom: 10px;
            color: #000;
            font-weight: 400;
            transition: all ease .3s;
            .v-list-item__icon{
                svg{
                    path{
                        fill: #15be79;
                    }
                }
            }
            .v-list-item__title{
                transition: all cubic-bezier(0, 1.03, 0.57, 0.92) .3s;
            }
            &:hover{
                .v-list-item__title{
                    color: $primarycolor;
                }
            }
        }
        img{
            width: 50%;
        }
        .v-list-item__action{
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            svg{
                width: 18px;
            }
            i{
                font-size: 18px;
            }
        }
        .v-card__actions{
            justify-content: center;
            padding-top: 15px;
            button{
                background-color: $primarycolor;
                border-color: $primarycolor;
                &.secondary-button{
                    background-color: #c2c2c2;
                    border-color: #c2c2c2;
                    color: #000;
                    margin-right: 10px;
                    @media (max-width: 330px){
                        margin-right: 0;
                        margin-bottom: 10px;
                    }
                }
                &:hover{
                    background-color: transparent;
                    color: $primarycolor;
                    &.secondary-button{
                        color: #c2c2c2;
                    }
                }
            }
        }
    }
    #modal-adicionar-endereco{
        font-family: 'Poppins';
        .v-card__title{
            color: $primarycolor;
            text-align: center;
            font-weight: 600;
            padding-bottom: 15px;
            text-transform: uppercase;
            word-break: break-word;
        }
        img{
            width: 50%;
        }
        .v-card__actions{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            padding: 5px 24px 8px 24px;
            .wrapper-btns{
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                button{
                    text-align: center;
                    justify-content: center;
                    color: #fff;
                    &.secondary-button{
                        background-color: #c2c2c2;
                        border-color: #c2c2c2;
                        color: #000;
                        margin-right: 10px;
                        @media (max-width: 330px){
                            margin-right: 0;
                            margin-bottom: 10px;
                        }
                    }
                    &:hover{
                        color: $primarycolor;
                        background-color: transparent;
                        &.secondary-button{
                            color: #c2c2c2;
                        }
                    }
                    @media (max-width: 450px){
                        font-size: 16px;
                        padding: 8px 16px;
                        align-items: center;
                    }
                }
                @media (max-width: 330px){
                    flex-direction: column;
                }
            }
        }
    }
</style>