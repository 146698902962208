<template>
    <v-dialog v-model="show_modal" max-width="600px" @click:outside="close">
        <v-card class="modal-busca">
            <div class="conteudo" :style="{transform: transform}">
                <v-text-field
                    class="primary-text-field"
                    :rules="[v => !!v || 'Campo obrigatório']" 
                    v-model="busca"
                    label="Busca"
                    solo
                    hide-details
                />
                <button class="primary-button" @click="buscar">
                    <v-icon color="#fff">mdi-magnify</v-icon>
                </button>
            </div>
            <Loader v-if="$store.getters.status_cardapio == 'loading'"/>
        </v-card>
    </v-dialog>
</template>

<script>
    // import store from '@/store'
    import Loader from '@/components/Loader2.vue'

    export default {
		name: 'ModalBusca',
        components: {
            Loader,
        },
        props: [
            "show"
        ],
        data: () => ({
            show_modal: false,
            busca: '',
            transform: 'initial'
        }),
		methods: {
            async close(){
                this.show_modal = await false
                setTimeout(() => {
                    this.$emit('handleClose')
                }, 300);
            },
            async init(){
                this.show_modal = this.show
                setTimeout(() => {
                    this.transform = 'translateY(0)'
                }, 300);
            },
            async buscar(){
                console.log('buscar: ', this.busca)
            },
		},
        computed: {
            
        },
        mounted(){
            this.init()
		},
	}
</script>

<style lang="scss">
    .v-dialog{
        box-shadow: none;
    }
    .modal-busca{
        .conteudo{
            background: #fff;
            position: fixed;
            left: 0;
            bottom: 0;
            border-radius: 0px !important;
            transform: translateY(100%);
            transition: all cubic-bezier(0, 1.03, 0.57, 0.92) .3s;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 5px;
            width: 100vw;
            @media (min-width: 768px) {
                padding: 10px;
            }
            .primary-text-field{
                flex: 1;
            }
            .v-input__slot{
                border: none;
            }
            input{
                padding: 0;
            }
            label{
                left: 0 !important;
            }
            button{
                padding: 0;
                width: 30px;
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                line-height: 1;
                font-size: 12px;
                transition: all ease .3s;
                &:hover{
                    i{
                        color: $primarycolor !important;
                    }
                }
            }
        }
    } 
</style>